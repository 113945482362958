.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #613fb6, #283e51);
  color: white;
}
.content {
  position: absolute;
  top: 50%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

h1 {
  font-size: 4rem;
  color: #ffffff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  animation: fade-in 1s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
}